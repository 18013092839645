<template>
    <div class="staff module-wrapper">
      <!-- <keep-alive> -->
        <router-view :key="$route.path"></router-view>
      <!-- </keep-alive> -->
    </div>
  </template>
  
  <script>
  export default {
    name: "Core",
    props: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
    // created () {},
    // beforeMount () {},
    // mounted () {},
    // components: {},
    // watch: {}
  };
  </script>
  
  <style lang="scss" scoped></style>